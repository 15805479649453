<template>
	<div>
		<a @click="this.$router.go(-1)" >
            <BaseIcon name= 'arrowLeft' class="mt-8 ml-8 absolute"/>
        </a>
		<iframe :src="baseURL+ '/wp-content/themes/szmm/single-ar.php'" frameborder="0" allow="camera" :style="'width: 100%; height: ' + height +'px;'"></iframe>
	</div>
</template>
<script>

import BaseIcon from '@/components/BaseIcon.vue';

export default {
	name: "3d",
    components: {
        BaseIcon,
    },
  	data () {
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
			height: window.innerHeight,
		}
	},
	mounted() {
	},
	methods: {
	},
	
}
</script>